import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const BrowseFlyers = () => {
    const [flyers, setFlyers] = useState([]);

    useEffect(() => {
        const fetchFlyers = async () => {
            try {
                const response = await fetch('/api/flyers', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                });  // Fetch data from backend
                const data = await response.json();           // Parse JSON response
                setFlyers(data);                              // Set data to state
                //console.log('Fetched Flyers:', data);         // Add this to debug
            } catch (error) {
                console.error('Error fetching flyers:', error);
            }
        };

        fetchFlyers();
    }, []);

    return (
        <div className="general-page">
            <h1>Browse Flyers</h1>
            <div className='main-container'>
                <div className="browse-flyers-container">

                    <div className="table-grid-3x">
                        {flyers.length > 0 ? flyers
                            .filter(flyer => flyer.active)
                            .map((flyer) => (
                                <Link to={`/flyers/${flyer.merchantId}`} key={flyer.merchantId} className="grid-card-link">
                                    <div className="grid-card">
                                        <img
                                            src={flyer.logoUrl
                                                ? `/api/flyers/proxy-image?url=${encodeURIComponent(flyer.logoUrl)}`
                                                : 'https://via.placeholder.com/150'}
                                            alt={flyer.merchantName}
                                            className="grid-image"
                                        />
                                        <h2>{flyer.merchantName}</h2>
                                        <p>
                                            {flyer.lastDownloaded && !isNaN(new Date(flyer.lastDownloaded).getTime())
                                                ? `Last Downloaded: ${new Date(flyer.lastDownloaded).toLocaleDateString()}`
                                                : 'Never Downloaded'}
                                        </p>
                                    </div>
                                </Link>
                            )) : <p>No flyers available.</p>}
                    </div>

                    <div className="back-to-main">
                        <Link to="/" className="back-link">Back to Main Menu</Link>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default BrowseFlyers;
