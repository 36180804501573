import React, { useState, useEffect, useContext } from 'react';
import { ShoppingContext } from './context/ShoppingContext';
import axios from 'axios';
import './css/CompareFlyers.css';

const CompareFlyers = () => {
  const { addItem, removeItem, shoppingList } = useContext(ShoppingContext);
  const [stores, setStores] = useState([
    { name: 'IGA', selected: true },
    { name: 'Metro', selected: true },
    { name: 'Adonis', selected: false },
    { name: 'Maxi', selected: false },
    { name: 'Costco', selected: false },
    { name: 'SuperC', selected: false },
  ]);
  const [searchItems, setSearchItems] = useState([]);
  const [category, setCategory] = useState('All');
  const [items, setItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [totalCost, setTotalCost] = useState(0);

  // Update total cost when shopping list changes
  useEffect(() => {
    const cost = shoppingList.reduce((sum, item) => sum + (item.price || 0), 0);
    setTotalCost(cost);
  }, [shoppingList]);

  const handleStoreChange = (index) => {
    const updatedStores = [...stores];
    updatedStores[index].selected = !updatedStores[index].selected;
    setStores(updatedStores);
  };

  const handleAddTag = (item) => {
    if (!searchItems.includes(item)) setSearchItems([...searchItems, item]);
  };

  const handleRemoveTag = (item) => {
    setSearchItems(searchItems.filter((i) => i !== item));
  };

  const fetchItems = async () => {
    const selectedStores = stores.filter((store) => store.selected).map((store) => store.name);
    const params = { stores: selectedStores, items: searchItems, category };

    try {
      const response = await axios.post('/api/flyers/compare', params);
      setItems(response.data.items);
      setCategories(['All', ...new Set(response.data.items.map((item) => item.category))]);
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };

  const toggleItemSelection = (itemId, store) => {
    const existingItem = shoppingList.find((item) => item.id === itemId);
    if (existingItem && existingItem.store === store) {
      removeItem(itemId); // Remove if already selected
    } else {
      addItem({ id: itemId, store, price: items.find((item) => item.id === itemId)?.price });
    }
  };

  const renderItemsTable = () => {
    const filteredItems = category === 'All' ? items : items.filter((item) => item.category === category);

    return (
      <table className="items-table">
        <thead>
          <tr>
            <th>Item</th>
            {stores.filter((store) => store.selected).map((store) => (
              <th key={store.name}>{store.name}</th>
            ))}
            <th>Selected</th>
          </tr>
        </thead>
        <tbody>
          {filteredItems.map((item) => (
            <tr key={item.id}>
              <td>
                <img src={item.image} alt={item.name} className="item-image" />
                {item.name}
              </td>
              {stores.filter((store) => store.selected).map((store) => (
                <td key={store.name}>
                  {item.prices[store.name] ? (
                    <div>
                      <span>${item.prices[store.name]}</span>
                      <button
                        className={`plus-button ${
                          shoppingList.some(
                            (listItem) => listItem.id === item.id && listItem.store === store.name
                          )
                            ? 'selected'
                            : ''
                        }`}
                        onClick={() => toggleItemSelection(item.id, store.name)}
                      >
                        {shoppingList.some(
                          (listItem) => listItem.id === item.id && listItem.store === store.name
                        )
                          ? '✔'
                          : '+'}
                      </button>
                    </div>
                  ) : (
                    'xx'
                  )}
                </td>
              ))}
              <td>
                ${shoppingList.find((listItem) => listItem.id === item.id)?.price || '0.00'}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div className="compare-flyers">
      <h2>Compare Flyers</h2>
      <div className="store-selection">
        {stores.map((store, index) => (
          <label key={store.name}>
            <input
              type="checkbox"
              checked={store.selected}
              onChange={() => handleStoreChange(index)}
            />
            {store.name}
          </label>
        ))}
      </div>
      <div className="item-input">
        <input
          type="text"
          placeholder="Enter an item..."
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleAddTag(e.target.value);
              e.target.value = '';
            }
          }}
        />
        <div className="tags">
          {searchItems.map((item) => (
            <span key={item} className="tag">
              {item}
              <button onClick={() => handleRemoveTag(item)}>x</button>
            </span>
          ))}
        </div>
        <button onClick={fetchItems}>Fetch Shopping List</button>
      </div>
      <div className="category-tabs">
        {categories.map((cat) => (
          <button
            key={cat}
            className={`tab ${cat === category ? 'active' : ''}`}
            onClick={() => setCategory(cat)}
          >
            {cat}
          </button>
        ))}
      </div>
      {renderItemsTable()}
      <div className="total-cost">
        <h3>Total Cost: ${totalCost.toFixed(2)}</h3>
      </div>
    </div>
  );
};

export default CompareFlyers;
