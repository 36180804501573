import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { ShoppingContext } from './context/ShoppingContext';
import './css/FlyerItems.css';

const FlyerItems = () => {
    const { merchantId } = useParams();
    const [items, setItems] = useState([]);
    const [merchant, setMerchant] = useState({ name: '', logoUrl: '' });
    const [validity, setValidity] = useState({ validFrom: null, validTo: null });
    const [loading, setLoading] = useState(true);
    const [selectedCategory, setSelectedCategory] = useState('All'); // State for selected category

    const { shoppingList, fetchShoppingList, addItemToShoppingList, removeItemFromShoppingList } = useContext(ShoppingContext);

    useEffect(() => {
        // Fetch items
        axios.get(`/api/flyers/items/${merchantId}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                const data = response.data;

                const enhancedItems = data.map((item) => ({
                    ...item,
                    isInShoppingList: Array.isArray(shoppingList) && shoppingList.some((listItem) => listItem?._id === item?._id)
                }));

                setItems(enhancedItems || []);

                if (enhancedItems.length > 0) {
                    setValidity({ validFrom: enhancedItems[0].validFrom, validTo: enhancedItems[0].validTo });
                } else {
                    setValidity({ validFrom: null, validTo: null });
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching items:', error);
                setLoading(false);
            });

        // Fetch merchant details
        axios.get(`/api/flyers/merchants/${merchantId}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {
                const mData = res.data;
                setMerchant({ name: mData.merchantName, logoUrl: mData.logoUrl });
            })
            .catch((error) => console.error('Error fetching merchant details:', error));
    }, [merchantId, shoppingList]);

    const renderPriceInfo = (item) => {
        let priceInfo = '';
        if (item.itemPrePriceText) priceInfo += `${item.itemPrePriceText} `;
        if (item.itemPrice !== null) priceInfo += `$${item.itemPrice}`;
        if (item.itemDiscount > 0) priceInfo += ` (${item.itemDiscount}% off)`;
        if (item.itemPostPriceText) priceInfo += ` ${item.itemPostPriceText}`;
        if (item.itemSaleStory) priceInfo += ` - ${item.itemSaleStory}`;
        return priceInfo;
    };

    const handleAddItem = async (item, e) => {
        const icon = e.currentTarget.querySelector('i');
        try {
            if (icon) {
                icon.style.color = 'gray';
                icon.className = 'fas fa-clock';
                icon.cursor = 'not-allowed';
            }
            await addItemToShoppingList(item.merchantName, item);
            await fetchShoppingList();
        } catch (error) {
            console.error('Error adding item to shopping list:', error);
        }
    };

    const handleRemoveItem = async (item, e) => {
        const icon = e.currentTarget.querySelector('i');
        try {
            if (icon) {
                icon.style.color = 'gray';
                icon.className = 'fas fa-clock';
                icon.cursor = 'not-allowed';
            }
            await removeItemFromShoppingList(item.merchantName, item);
            await fetchShoppingList();
        } catch (error) {
            console.error('Error removing item from shopping list:', error);
        }
    };

    const groupedItems = (Array.isArray(items) ? items : []).reduce((groups, item) => {
        const category = item.mainCategoryEn || 'Uncategorized';
        if (!groups[category]) groups[category] = [];
        groups[category].push(item);
        return groups;
    }, {});

    const displayedItems = selectedCategory === 'All' 
        ? items 
        : groupedItems[selectedCategory] || [];

    if (loading) {
        return <div className="general-page"><h1>Loading...</h1></div>;
    }

    return (
        <div className="general-page">
            <h1>
                <img src={`/api/flyers/proxy-image?url=${encodeURIComponent(merchant.logoUrl)}`} alt={`${merchant.name} logo`} />
                <br />
                {merchant.name}
            </h1>
            <div className='main-container'></div>

            <div className="items-container">
                {validity.validFrom && validity.validTo && (
                    <p>Valid from: {new Date(validity.validFrom).toLocaleDateString()} to {new Date(validity.validTo).toLocaleDateString()}</p>
                )}

                {items.length === 0 ? (
                    <p>No flyer items found for this merchant.</p>
                ) : (
                    <>
                        <nav className="category-tabs">
                            <button 
                                className={`tab ${selectedCategory === 'All' ? 'active' : ''}`}
                                onClick={() => setSelectedCategory('All')}
                            >
                                All
                            </button>
                            {Object.keys(groupedItems).map((category) => (
                                <button 
                                    key={category} 
                                    className={`tab ${selectedCategory === category ? 'active' : ''}`}
                                    onClick={() => setSelectedCategory(category)}
                                >
                                    {category}
                                </button>
                            ))}
                        </nav>

                        <table className="items-table">
                            <tbody>
                                {displayedItems.map((item) => (
                                    <tr key={item._id}>
                                        <td>
                                            <img
                                                src={`/api/flyers/proxy-image?url=${encodeURIComponent(item.itemImageUrl)}`}
                                                alt={item.itemName}
                                                className="thumbnail"
                                            />
                                        </td>
                                        <td>
                                            {item.itemName} <small>{item.bodyEn}</small><br /><small>{item.itemBrand}</small>
                                        </td>
                                        <td>{renderPriceInfo(item)}</td>
                                        <td>
                                            {item.isInShoppingList ? (
                                                <button
                                                    onClick={(e) => handleRemoveItem(item, e)}
                                                    className="icon-button"
                                                >
                                                    <i className="fas fa-circle-check" style={{ color: '#28a745', fontSize: '18px' }}></i>
                                                </button>
                                            ) : (
                                                <button
                                                    onClick={(e) => handleAddItem(item, e)}
                                                    className="icon-button"
                                                >
                                                    <i className="fas fa-circle-plus" style={{ color: '#007bff', fontSize: '18px' }}></i>
                                                </button>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </>
                )}

                <div className="back-to-main">
                    <Link to="/browse-flyers" className="back-link">Back to Flyers</Link>
                </div>
            </div>
        </div>
    );
};

export default FlyerItems;
